import React from "react";
import { useWindowWidth } from "../../breakpoints";
import "./style.css";
import mixpanel from 'mixpanel-browser';

mixpanel.init("3637e3069cdfb55c6e9e216ff33d7879", {debug: true, ignore_dnt: true});

export const Waitlist = () => {
  const screenWidth = useWindowWidth();

  return (
    <div
      className="waitlist"
      style={{
        backgroundColor: screenWidth < 1372 ? "#ffffff" : undefined,
        height: screenWidth >= 1372 ? "880px" : undefined,
        minHeight: screenWidth < 1372 ? "100vh" : undefined,
        minWidth: screenWidth < 1372 ? "375px" : screenWidth >= 1372 ? "1372px" : undefined,
      }}
    >
      <div
        className="navigation-left"
        style={{
          backgroundColor: screenWidth < 1372 ? "#7086ff" : screenWidth >= 1372 ? "#6f86ff" : undefined,
          height: screenWidth < 1372 ? "38px" : screenWidth >= 1372 ? "138px" : undefined,
          justifyContent: screenWidth >= 1372 ? "center" : undefined,
          padding: screenWidth < 1372 ? "0px 20px" : screenWidth >= 1372 ? "0px 51px" : undefined,
        }}
      >
        {screenWidth < 1372 && (
          <>
            <img className="logo" alt="Logo" src="/img/logo.png" />
            <div className="navigation-right">
              <div className="text-wrapper">Docs</div>
              <div className="text-wrapper">Features</div>
              <div className="main-cta">
                <div className="overlap-group">
                  <div className="rectangle" />
                  <div className="div">Start Bulding</div>
                </div>
              </div>
            </div>
          </>
        )}

        {screenWidth >= 1372 && (
          <>
            <div className="frame">
              <img className="img" alt="Logo" src="/img/logo.png" />
            </div>
            <div className="navigation-right-2">
              <div className="text-wrapper-2">Contact</div>
              <div className="text-wrapper-2">Docs</div>
              <div className="group-wrapper">
                <div className="group">
                  <div className="div-wrapper">
                    <div className="text-wrapper-3">Start building</div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div
        className="frame-2"
        style={{
          flex: screenWidth < 1372 ? "1" : undefined,
          flexDirection: screenWidth < 1372 ? "column" : undefined,
          flexGrow: screenWidth < 1372 ? "1" : undefined,
          gap: screenWidth < 1372 ? "38px" : screenWidth >= 1372 ? "122px" : undefined,
          height: screenWidth >= 1372 ? "742px" : undefined,
          justifyContent: screenWidth < 1372 ? "center" : undefined,
          padding: screenWidth < 1372 ? "47px 0px 0px" : undefined,
        }}
      >
        <div
          className="frame-3"
          style={{
            alignItems: screenWidth < 1372 ? "flex-start" : screenWidth >= 1372 ? "center" : undefined,
            flex: screenWidth < 1372 ? "0 0 auto" : screenWidth >= 1372 ? "1" : undefined,
            flexDirection: screenWidth < 1372 ? "column" : undefined,
            flexGrow: screenWidth >= 1372 ? "1" : undefined,
            gap: screenWidth < 1372 ? "22px" : undefined,
            padding: screenWidth < 1372 ? "0px 20px" : undefined,
            width: screenWidth < 1372 ? "100%" : undefined,
          }}
        >
          <div
            className="frame-4"
            style={{
              alignItems: screenWidth < 1372 ? "flex-start" : undefined,
              backgroundColor: screenWidth >= 1372 ? "#7086ff" : undefined,
              display: screenWidth < 1372 ? "flex" : undefined,
              flex: screenWidth < 1372 ? "0 0 auto" : undefined,
              flexDirection: screenWidth < 1372 ? "column" : undefined,
              gap: screenWidth < 1372 ? "17px" : undefined,
              width: screenWidth < 1372 ? "100%" : screenWidth >= 1372 ? "539px" : undefined,
            }}
          >
            {screenWidth < 1372 && (
              <>
                <div className="text-wrapper-4">Let’s get in contact.</div>
                <p className="p">
                  We use AI to decrease your time to market cloud apps, decrease your infrastructure cost, or to migrate
                  your on-premise applications to the cloud.
                </p>
              </>
            )}
          </div>
          <div
            className="frame-5"
            style={{
              alignItems: screenWidth < 1372 ? "flex-start" : screenWidth >= 1372 ? "center" : undefined,
              display: screenWidth < 1372 ? "flex" : screenWidth >= 1372 ? "inline-flex" : undefined,
              gap: screenWidth < 1372 ? "1px" : undefined,
              marginLeft: screenWidth >= 1372 ? "-476px" : undefined,
              width: screenWidth < 1372 ? "100%" : undefined,
            }}
          >
            {screenWidth < 1372 && (
              <>
                <input className="enter-email-address" onChange={(e) => {
                  mixpanel.track("Contact me entered", {email: e.target.value})
                }} />
                <div className="frame-6" onClick={() => { mixpanel.track('Submit', {email: e.target.value}); alert("We'll be in contact soon"); }}>
                  <div className="text-wrapper-5">Join now</div>
                </div>
              </>
            )}

            {screenWidth >= 1372 && (
              <>
                <img className="vector" alt="Vector" src="/img/vector-52.svg" />
                <img className="waiting" alt="Waiting" src="/img/waiting.png" />
              </>
            )}
          </div>
        </div>
        <div
          className="frame-7"
          style={{
            flexDirection: screenWidth >= 1372 ? "column" : undefined,
            gap: screenWidth >= 1372 ? "22px" : undefined,
            padding: screenWidth >= 1372 ? "0px 126px" : undefined,
            width: screenWidth < 1372 ? "100%" : undefined,
          }}
        >
          <div
            className="rectangle-2"
            style={{
              alignItems: screenWidth >= 1372 ? "flex-start" : undefined,
              alignSelf: screenWidth < 1372 ? "stretch" : undefined,
              backgroundColor: screenWidth < 1372 ? "#7086ff" : undefined,
              display: screenWidth >= 1372 ? "flex" : undefined,
              flex: screenWidth < 1372 ? "1" : screenWidth >= 1372 ? "0 0 auto" : undefined,
              flexDirection: screenWidth >= 1372 ? "column" : undefined,
              flexGrow: screenWidth < 1372 ? "1" : undefined,
              gap: screenWidth >= 1372 ? "17px" : undefined,
              marginLeft: screenWidth >= 1372 ? "-28.00px" : undefined,
              marginRight: screenWidth >= 1372 ? "-28.00px" : undefined,
              width: screenWidth >= 1372 ? "429px" : undefined,
            }}
          >
            {screenWidth >= 1372 && (
              <>
                <div className="text-wrapper-6">Let’s get in contact.</div>
                <p className="text-wrapper-7">
                  We use AI to decrease your time to market cloud apps, decrease your infrastructure cost, or to migrate
                  your on-premise applications to the cloud.
                </p>
              </>
            )}
          </div>
          <div
            className="frame-8"
            style={{
              alignItems: screenWidth < 1372 ? "center" : screenWidth >= 1372 ? "flex-start" : undefined,
              alignSelf: screenWidth < 1372 ? "stretch" : undefined,
              display: screenWidth < 1372 ? "flex" : screenWidth >= 1372 ? "inline-flex" : undefined,
              flex: screenWidth >= 1372 ? "0 0 auto" : undefined,
              gap: screenWidth >= 1372 ? "1px" : undefined,
              justifyContent: screenWidth < 1372 ? "center" : undefined,
              marginLeft: screenWidth < 1372 ? "-476px" : screenWidth >= 1372 ? "-28.00px" : undefined,
              marginRight: screenWidth >= 1372 ? "-28.00px" : undefined,
              width: screenWidth < 1372 ? "375px" : undefined,
            }}
          >
            {screenWidth < 1372 && (
              <>
                <img className="vector-2" alt="Vector" src="/img/vector-52-1.svg" />
                <img className="waiting-2" alt="Waiting" src="/img/waiting-1.png" />
              </>
            )}

            {screenWidth >= 1372 && (
              <>
                <div className="frame-wrapper">
                  <input className="enter-email-address-wrapper" onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="frame-9" onClick={() => { mixpanel.track('Submit', {email}); alert("We'll be in contact soon"); }}>
                  <div className="text-wrapper-5">Join now</div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
